.menu-card-edit {
    display: none;
}

.show-menu-editable {
    display: block;
}

.border-show {
    border: 2px solid teal;
}