.react-datepicker-wrapper {
    margin-top: 10px;
    width: 100%;
}

.react-datepicker {
    font-family: -apple-system, BlinkMacSystemFont, 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border: none;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.react-datepicker__input-container {
    border-color: turquoise;
    /* padding: 0 10px; */
    width: 100%;
    height: 100%;
    outline: 0;
}

.react-datepicker__input-container > input {
    color: #666;
    font-size: 15px;
    /* padding: 0 10px; */
    width: 100%;
    height: 100%;
    outline: 0;
}

.react-datepicker__input-container > input:focus{
    border-color: turquoise;
}

.react-datepicker__header  {
    background-color: #EDF2F9;
}

.react-datepicker__day--selected, 
.react-datepicker__day--selected:hover, 
.react-datepicker__day--selected:focus, 
.react-datepicker__day--today {
    background-color: turquoise;
    border-radius: 4px;
    border:none;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    margin-top: 0;
}

.react-datepicker__close-icon::after {
    background-color: unset;
    border-radius: unset;
    font-size: 1.5rem;
    font-weight: bold;
    color: hsl(0,0%,80%);
    height: 20px;
    width: 20px;
}

.react-datepicker__close-icon::after:hover {
    color: hsl(0,0%,70%)
}


