.wrapperClassName {
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 5px;
}

.editorClassName {
  padding-left: 10px;
  height: 350px;
}
/* .rich-editor {
  overflow-y: scroll;
} */
.listRow:hover {
  background-color: #edf2f9;
}

/* h1, h2, h3 {
    font-weight: 700!important;
} */
