/* #blogDetails ul, li { 
    margin-left: 1.5em; 
} 

#blogDetails ol, li { 
    margin-left: 1.5em; 
} 


#blogDetails blockquote { 
    text-align: justify; 
    border-left: 5px solid #dddddd; 
    padding-left: 40px; 
    margin: 30px 0; 
} 


#blogDetails pre {
    background: #f5f5f5;
    border-radius: 3px;
    padding: 1px 10px;
    margin: 15px 0;
    padding: 10px 10px;
}

#blogDetails p {
    margin: 15px 0px;
}  */

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
