
.web-pagination { 
    display: flex; 
    padding-left: 0; 
    list-style: none; 
    border-radius: 0.25rem; 
    margin-bottom: 30px;
} 

ol, ul, dl { 
    margin-top: 0; 
    margin-bottom: 1rem; 
} 

.web-pagination-page-link:hover {
    z-index: 2;
    color: black;
    text-decoration: none;
    background-color: #dee2e6;
    border-color: #dee2e6;
} 

.web-pagination-page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: black;
    background-color: #fff;
    border: 1px solid #dee2e6;
} 

.web-pagination-page-item.web-pagination-active .web-pagination-page-link {
    z-index: 1;
    color: #fff;
    background-color: gray;
    border-color: gray;
}

