.active { 
    width: 260px !important; 
} 

.tabOff {
    width: 60px;
    text-align: center;
}

.tabOn {
    width: 260px;
    text-align: center;
}


.navigation { 
    width: 60px; 
    height: 100%; 
    transition: all 0.4s; 
    overflow: hidden; 
    z-index: 10;
} 


.navigation ul {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-left: 0;
}


.navigation li {
    position: relative;
    width: 100% !important;
    list-style: none;
} 

.menuItem:hover { 
    background: #4A5568; 
} 


.navigation a {
    display: flex;
    width: 100% !important;
    text-decoration: none;
    color: #fff;
} 


.icon { 
    min-width: 60px;
    height: 50px;
    line-height: 50px;
    text-align: center;
} 

.icon2 { 
    margin-left: 20px; 
    height: 40px;
    line-height: 40px;
} 

.title {
    /* font-family: 'Open Sans', sans-serif; */
    height: 50px;
    line-height: 50px;
    /* padding: 0 10px; */
} 

.title2 { 
    height: 40px; 
    line-height: 40px; 
    /* padding-left: -10px; */
} 



@media screen and (max-width: 991px) { 
    .active { 
        width: 60px !important; 
    } 
    
    .navigation { 
        width: 250px; 
    } 
} 


@media screen and (max-width: 600px) { 
    .active { 
        /* display: none;  */ 
        width: 0% !important; 
        /* 
            if any issue arise, 
                uncomment display in active class && width 75% !important in navigation class
                & comment width 0% in active & width 75% in navigation class
            I change that to animate sidenav in mobile view 
        */ 
    } 
    
    .navigation {
        position: fixed;
        top: 0;
        left: 0;
        /* width: 75% !important; */
        width: 75%;
        transition: all 0.5s;
        /* -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s; */
    } 
} 





/* =================================================================== */
/* =================================================================== */
/* =================================================================== */
/* =================================================================== */



