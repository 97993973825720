/*
-----------------------------------------------------------------------------
    Page global styles
-----------------------------------------------------------------------------
*/

.lan-container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 50px 0;
}

#pageMain img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -15px;
  padding: 15px;
}

.flex > * {
  margin-left: 15px;
  margin-bottom: 15px;
}

.section {
  padding: 50px 0;
}

/*
---------------------------------------------------
    City section style
---------------------------------------------------
*/

#sectionCity {
  padding: 50px 0;
  background-color: bisque;
}

/*
---------------------------------------------------
    Footer style
---------------------------------------------------
*/

#footer {
  padding: 60px 0;
  background-color: #e2e8f0;
}

#footer .footer-wrapper {
  justify-content: center;
}

#footer .footer-left,
#footer .footer-right {
  flex: 1 1 18rem;
  text-align: center;
  align-self: center;
}

@media (max-width: 575.98px) {
  #footer .footer-left {
    order: 2;
  }

  #footer .footer-right {
    order: 1;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  #footer .footer-right {
    order: 2;
  }

  #footer .footer-left {
    order: 1;
  }
}

#footer .footer-right .footer-right-item {
  flex: 1 1 25%;
  text-align: center;
}

#footer .footer-left h2 {
  margin: 0;
}

#footer .footer-right h5 {
  margin: 0 0 10px 0;
}

#footer .footer-right .footer-link {
  font-size: 0.8rem;
}

#footer .footer-right .footer-link > div {
  padding: 4px 0;
}
.featureGrid {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}

.gridChild{
  grid-column-end: auto;
}